<template>
  <div>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit" ref="_form">
        <div class="columns">
          <div class="column is-8">
            <ValidationProvider
              name="service_name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors[0] ? 'is-danger' : ''" expanded>
                <b-input v-model="service.name" placeholder="Nome"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column">
            <b-button native-type="submit" type="is-primary">Enviar</b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <b-table
      @click="setFilter"
      :row-class="(row, index) => row._id === service._id && 'is-info'"
      :data="services"
      :columns="columns"
    ></b-table>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      service: {},
      services: [],
      columns: [
        {
          field: "id",
          label: "ID",
          visible: false,
        },
        {
          field: "name",
          label: "Nome",
        },
      ],
    };
  },
  created() {
    this.$http.get(`${process.env.VUE_APP_URI}_service`).then((response) => {
      this.services = response.data;
    });
  },
  methods: {
    onSubmit() {
      const self = this;

      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        if (!self.service._id)
          self.$http
            .post(`${process.env.VUE_APP_URI}_service`, self.service)
            .then((response) => {
              self.$set(self.service, "_id", response.data._id);
              self.services.push(self.service);
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-success",
              });
            });

        if (self.service._id)
          self.$http
            .put(
              `${process.env.VUE_APP_URI}_service/${self.service._id}`,
              self.service
            )
            .then(() => {
              self.services = self.services.map(function (v) {
                if (v._id == self.service._id) v = self.service;
                return v;
              });
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-info",
              });
            });
      });
    },
    setFilter(v) {
      this.service = JSON.parse(JSON.stringify(v));
    },
  },
};
</script>
<style>
tr.is-info {
  background: #167df0;
  color: #fff;
}
</style>